import { faQuoteRight, faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as React from "react"
import ArticleCard from "../components/article-card"
import Button from "../components/button"
import ContactForm from "../components/contact-form"
import Emphasis from "../components/emphasis"
import H1 from "../components/h1"
import H2 from "../components/h2"
import Subtitle from "../components/h4"
import Layout from "../components/layout"
import ProjectCard from "../components/project-card"
import Seo from "../components/seo"

export default function HomePage() {
  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.refresh()

  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allContentfulProject(limit: 2, sort: { fields: updatedAt, order: DESC }) {
        nodes {
          title
          url
          heroImage {
            gatsbyImageData
          }
          summary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      allContentfulArticle(limit: 2, sort: { fields: updatedAt, order: DESC }) {
        nodes {
          title
          url
          heroImage {
            gatsbyImageData
          }
          tags
          minutesToRead
        }
      }
    }
  `)
  console.debug("Homepage Data: ", data)

  const projects = data.allContentfulProject.nodes.map(node => {
    return {
      summary: node.summary.childMarkdownRemark.html,
      image: getImage(node.heroImage),
      url: `/projects/${node.url}`,
    }
  })

  const articles = data.allContentfulArticle.nodes.map(node => {
    return {
      title: node.title,
      image: getImage(node.heroImage),
      url: `/articles/${node.url}`,
      minutesToRead: node.minutesToRead,
      tags: node.tags,
    }
  })

  const reviews = [
    {
      username: "Jason Hollingsworth",
      profileImage: (
        <StaticImage
          src="../images/jason-hollingsworth.png"
          alt="Jason Hollingsworth"
          layout="fullWidth"
          objectPosition="center"
          objectFit="cover"
          className="!absolute !inset-0"
          placeholder="none"
        />
      ),
      stars: 5,
      review:
        "Tom’s Crew did a great job spraying my pole building, walls and ceiling. They did everything I asked and were reasonably priced.",
    },
    {
      username: "Mike Miller",
      profileImage: null,
      stars: 5,
      review:
        "Tom and his crew did an excellent job with spray foam insulation in a sewage treatment plant control building. Even amidst the chaos of resin shortages, Canopy was able to deliver an exceptional insulation system in the time frame they promised. Super clean and utmost professionals, Canopy will be our first choice in the future for insulation projects.",
    },
    {
      username: "Luke Swyers",
      profileImage: null,
      stars: 5,
      review:
        "Very knowledgeable and professional with a growing array of services offered, and they provide the highest quality of work with the intention of customer satisfaction. I would highly recommend this company to any business or homeowner who wants to save on their utilities and provide their investment with the best protection.",
    },
  ]

  const [selectedReviewIdx, setSelectedReviewIdx] = React.useState(0)
  const [selectedProjectIdx, setSelectedProjectIdx] = React.useState(0)

  const reviewRef = React.createRef()
  const projectCardRef = React.createRef()

  React.useEffect(() => {
    const fadeInAnimation = gsap.from(".gsap-fade-in", {
      opacity: 0,
      duration: 2,
    })

    return () => fadeInAnimation.kill()
  }, [])

  React.useEffect(() => {
    const reviewFadeTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: reviewRef.current,
        start: "top bottom-=100px",
        end: "bottom top+=100px",
      },
    })

    reviewFadeTimeline.to(reviewRef.current, { opacity: 1, duration: 1 })
    reviewFadeTimeline.to(reviewRef.current, {
      opacity: 0,
      duration: 1,
      delay: 5,
      onComplete: () => {
        console.debug("review onComplete")
        setSelectedReviewIdx(prevIndex =>
          prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
        )
      },
    })

    return () => reviewFadeTimeline.kill()
  }, [reviewRef, reviews.length, selectedReviewIdx])

  React.useEffect(() => {
    const projectTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: projectCardRef.current,
        start: "top bottom-=100px",
        end: "bottom top+=100px",
      },
    })

    projectTimeline.to(projectCardRef.current, { opacity: 1, duration: 1 })

    if (projects.length > 1) {
      projectTimeline.to(projectCardRef.current, {
        opacity: 0,
        duration: 1,
        delay: 5,
        onComplete: () => {
          console.debug("project onComplete")
          setSelectedProjectIdx(prevIndex =>
            prevIndex === projects.length - 1 ? 0 : prevIndex + 1
          )
        },
      })
    }

    return () => projectTimeline.kill()
  }, [projectCardRef, projects.length, selectedProjectIdx])

  const selectedProject = projects[selectedProjectIdx]
  const selectedReview = reviews[selectedReviewIdx]

  return (
    <Layout>
      <Seo title="Home" />

      <section id="hero" className="relative">
        {/* TODO : Make this image dynamic */}
        <StaticImage
          src="../images/hero.jpeg"
          alt=""
          layout="fullWidth"
          objectPosition="center"
          objectFit="cover"
          className="!absolute !inset-0"
          placeholder="blurred"
        />
        <div className="flex flex-col backdrop-blur-sm backdrop-brightness-[.35] z-10">
          <div className="gsap-fade-in lg:!pt-32 lg:!pb-16 py-16 px-4 grow flex flex-col lg:justify-center lg:items-center text-center">
            <H1 className="hidden lg:block text-secondary">
              Protect. Preserve. <span className="text-primary">Insulate.</span>
            </H1>
            <div className="lg:hidden items-start">
              <H1 className="text-secondary">Protect.</H1>
              <H1 className="text-secondary">Preserve.</H1>
              <H1 className="text-primary">Insulate.</H1>
            </div>
          </div>
          <div className="gsap-fade-in py-12 lg:!pb-32 grow flex justify-center space-x-4">
            <AnchorLink to="/#lets-get-started" title="Let's get started">
              <Button className="hidden lg:block text-3xl">
                Get a free Quote
              </Button>
              <Button className="lg:hidden !p-6 text-4xl">
                Get a free Quote
              </Button>
            </AnchorLink>
            <AnchorLink
              to="/#the-canopy-difference"
              className="hidden lg:block"
            >
              <Button className="text-3xl" varient="secondary">
                Why Us?
              </Button>
            </AnchorLink>
          </div>
        </div>
      </section>

      <section id="the-canopy-difference" className="gsap-fade-in">
        <H2 className="text-center pt-4">The Canopy Difference</H2>
        <Subtitle className="text-center p-2">
          When it comes to taking control of your environment, Canopy has you
          covered!
        </Subtitle>
        <div className="flex flex-col lg:flex-row items-stretch justify-center space-y-4 lg:!space-y-0 lg:space-x-4 p-6">
          <div className="basis-2/5 flex flex-col rounded-lg overflow-hidden relative">
            <StaticImage
              src="../images/stock/spray-foam-texture.jpeg"
              alt=""
              layout="fullWidth"
              objectPosition="center"
              objectFit="cover"
              className="!absolute !inset-0"
              placeholder="blurred"
            />
            <div className="relative grow flex flex-col p-4 z-10 backdrop-brightness-50 text-secondary">
              <div className="text-center">
                <Emphasis>Spray Foam Insulation</Emphasis>
              </div>
              <ul className="grow list-disc list-outside px-4 py-6 italic font-medium text-lg">
                <li>Save money</li>
                <li>Increase comfort</li>
                <li>Say goodbye to pests</li>
              </ul>
              <AnchorLink to="services#spray-foam-insulation">
                <Button
                  varient="secondary"
                  className="w-full text-tertiary hover:!scale-100 hover:!bg-primary-highlighted hover:!border-primary-highlighted"
                >
                  Why Spray Foam?
                </Button>
              </AnchorLink>
            </div>
          </div>
          <div className="basis-2/5 flex flex-col rounded-lg overflow-hidden relative">
            <StaticImage
              src="../images/polyurea-coating-job.jpg"
              alt=""
              layout="fullWidth"
              objectPosition="center"
              objectFit="cover"
              className="!absolute !inset-0"
              placeholder="blurred"
            />
            <div className="grow flex flex-col p-4 z-10 backdrop-brightness-50 text-secondary">
              <div className="text-center">
                <Emphasis>Polyurea Coating</Emphasis>
              </div>
              <ul className="grow list-disc list-outside px-4 py-6 italic font-medium text-lg">
                <li>Reduce maintenance costs</li>
                <li>Protect, restore, and prolong the life of your surfaces</li>
                <li>Mother nature doesn't stand a chance</li>
              </ul>
              <AnchorLink to="services#polyurea-coating">
                <Button
                  varient="secondary"
                  className="w-full text-tertiary hover:!scale-100 hover:!bg-primary-highlighted hover:!border-primary-highlighted"
                >
                  Why Polyurea Coating?
                </Button>
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>

      <section
        id="what-our-customers-say"
        className="bg-tertiary text-secondary flex flex-col p-4"
      >
        {/* 
        TODO : Fetch data from Google if possible. Then rotate display
        https://developers.google.com/my-business/content/review-data 
        */}
        <H2 className="text-center">What our customers say</H2>
        <div
          ref={reviewRef}
          className="flex lg:h-80 items-center justify-center p-4 opacity-0"
        >
          {/* Desktop */}
          <div className="hidden lg:flex w-1/4 justify-center">
            <figure className="relative h-32 w-32 rounded-full">
              {selectedReview.profileImage}
            </figure>
          </div>
          <div className="hidden lg:block w-1/2 text-center">
            <div className="text-primary py-6">
              {Array(selectedReview.stars)
                .fill(null, 0, selectedReview.stars)
                .map((reviewStar, index) => (
                  <FontAwesomeIcon key={index} icon={faStar} size="3x" />
                ))}
            </div>
            <div className="italic pb-4">"{selectedReview.review}"</div>
            <div className="text-secondary font-semibold py-4 -ml-2">
              &mdash; {selectedReview.username}
            </div>
          </div>
          <div className="hidden lg:block w-1/4 text-center">
            <FontAwesomeIcon icon={faQuoteRight} size="5x" />
          </div>

          {/* Mobile */}
          <div className="lg:hidden w-full flex flex-col items-center justify-center text-center">
            <div className="text-primary py-6">
              {Array(selectedReview.stars)
                .fill(null, 0, selectedReview.stars)
                .map((reviewStar, index) => (
                  <FontAwesomeIcon key={index} icon={faStar} size="2x" />
                ))}
            </div>
            <div className="italic pb-4">"{selectedReview.review}"</div>
            {selectedReview.profileImage && (
              <figure className="relative h-32 w-32 rounded-full">
                {selectedReview.profileImage}
              </figure>
            )}
            <div className="text-secondary font-semibold py-4 -ml-2">
              &mdash; {selectedReview.username}
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button
            href="https://g.page/r/CXvzEIp1KarkEAg/review"
            className="text-tertiary text-xl"
          >
            Leave us a review
          </Button>
        </div>
      </section>

      <section
        id="our-work"
        className="flex flex-col items-center justify-center"
      >
        <H2 className="text-center p-4">See our work</H2>
        <div className="p-8 w-full lg:!w-3/4">
          <ProjectCard
            ref={projectCardRef}
            project={selectedProject}
            className="opacity-0"
          />
        </div>

        {/* TODO : Break out into GallerySelector component that wraps children with generic internals */}
        {projects.length > 1 && (
          <>
            <nav className="flex justify-center m-4">
              <ol className="flex items-center">
                {projects.map((project, index) => (
                  <li
                    key={index}
                    className={index === projects.length - 1 ? "" : "pr-3"}
                  >
                    <div
                      role="button"
                      tabIndex={index}
                      // aria-checked={selectedProjectIdx === index}
                      aria-label="Project gallery selector button"
                      className={`w-4 h-4 rounded-full cursor-pointer ${
                        selectedProjectIdx === index
                          ? "bg-primary"
                          : "bg-gray-300"
                      }`}
                      onClick={() => setSelectedProjectIdx(index)}
                      onKeyDown={() => setSelectedProjectIdx(index)}
                    ></div>
                  </li>
                ))}
              </ol>
            </nav>
            <Link to="/projects">
              <Button varient="secondary">All Projects</Button>
            </Link>
          </>
        )}
      </section>

      <section
        id="articles"
        className="flex flex-col items-center justify-center"
      >
        <H2 className="text-center p-4">Latest articles</H2>
        <div className="p-8 grid grid-cols-1 lg:grid-cols-2 w-full lg:!w-3/4 justify-center gap-y-8 gap-x-8">
          {articles.map((article, index) => (
            <ArticleCard article={article} key={index} />
          ))}
        </div>
        <Link to="/articles">
          <Button varient="secondary">All Articles</Button>
        </Link>
      </section>

      <ContactForm id="lets-get-started" />
    </Layout>
  )
}
