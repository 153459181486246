import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltips from "@material-tailwind/react/Tooltips"
import TooltipsContent from "@material-tailwind/react/TooltipsContent"
import { Link } from "gatsby"
import * as React from "react"
import Button from "./button"
import CarbonCreekIcon from "./carbon-creek-icon"
import H3 from "./h3"

const Footer = () => {
  const ccIconRef = React.useRef()

  return (
    <>
      <footer className="hidden lg:flex flex-col bg-tertiary px-12 py-4 text-secondary">
        <div className="flex">
          <div className="basis-1/3 justify-self-start flex flex-col">
            <Link
              to="/services"
              className="text-sm hover:text-primary-highlighted active:text-primary-darkened"
            >
              Our Services
            </Link>

{/* TODO : Restore */}
            {/* <Link
              to="/projects"
              className="text-sm hover:text-primary-highlighted active:text-primary-darkened"
            >
              Our Work
            </Link> */}

            <Link
              to="/sitemap/sitemap-index.xml"
              className="text-sm hover:text-primary-highlighted active:text-primary-darkened"
            >
              Sitemap
            </Link>
          </div>
          <div className="basis-1/3 text-center">
            <Link to="/contact">
              <H3 className="text-primary hover:text-primary-highlighted active:text-primary-darkened hover:scale-110 duration-200">
                Ready to get started?
              </H3>
            </Link>
          </div>
          <nav className="basis-1/3 justify-self-end text-right">
            <a
              href="tel:814-229-0180"
              className="p-2"
              alt="Call Canopy Insulation at (814)-229-0180"
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                alt="Phone icon"
                size="2x"
                className="hover:text-primary-highlighted active:text-primary-darkened hover:scale-125 duration-200"
              />
            </a>

            <a href="https://www.facebook.com/canopysfi" className="p-2">
              <FontAwesomeIcon
                icon={faFacebookF}
                alt="Facebook icon"
                size="2x"
                className="hover:text-primary-highlighted active:text-primary-darkened hover:scale-125 duration-200"
              />
            </a>

            <a href="https://www.instagram.com/canopysfi/" className="p-2">
              <FontAwesomeIcon
                icon={faInstagram}
                alt="Instagram icon"
                size="2x"
                className="hover:text-primary-highlighted active:text-primary-darkened hover:scale-125 duration-200"
              />
            </a>

            <a href="mailto:canopysfi@gmail.com" className="p-2">
              <FontAwesomeIcon
                icon={faEnvelope}
                alt="Email icon"
                size="2x"
                className="hover:text-primary-highlighted active:text-primary-darkened hover:scale-125 duration-200"
              />
            </a>
          </nav>
        </div>
        <div className="flex pt-4 text-xs items-end">
          <div className="basis-1/3 justify-self-start">
            <span>© {new Date().getFullYear()} Canopy Insulation, Co.</span>
          </div>
          <div className="basis-1/3 justify-self-center">
            <a
              ref={ccIconRef}
              href="https://carboncreek.agency"
              target="_blank"
              rel="noreferrer"
            >
              <CarbonCreekIcon className="h-5 w-5 mx-auto fill-secondary hover:fill-primary-highlighted hover:stroke-primary-highlighted" />
            </a>
            <Tooltips placement="top" ref={ccIconRef}>
              <TooltipsContent>
                Made with &hearts; by Carbon Creek
              </TooltipsContent>
            </Tooltips>
          </div>
          <div className="basis-1/3 text-right">
            <a
              href="https://goo.gl/maps/fDb9GJ8QJ6t8tZg48"
              className="text-primary hover:text-primary-highlighted active:text-primary-darkened underline"
            >
              10811 Pennsylvania 66, Clarion, PA 16214
            </a>
          </div>
        </div>
      </footer>

      <footer className="lg:hidden p-4 bg-tertiary text-secondary flex flex-col">
        <div className="text-center">
          <Link to="/contact">
            <H3 className="text-primary hover:text-primary-highlighted active:text-primary-darkened hover:scale-110 duration-200">
              Ready to get started?
            </H3>
          </Link>
        </div>

        <div className="text-center p-4">
          <Button
            href="tel:814-229-0180"
            icon={faPhoneAlt}
            alt="Call Canopy Insulation at (814)-229-0180"
            className="text-tertiary hover:bg-primary-highlighted active:bg-primary-darkened active:shadow-none text-2xl"
          >
            814.229.0180
          </Button>
        </div>

        <div className="text-center space-x-8">
          <a href="https://www.facebook.com/canopysfi">
            <FontAwesomeIcon
              icon={faFacebookF}
              size="2x"
              className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
            />
          </a>

          <a href="https://www.instagram.com/canopysfi/">
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
            />
          </a>

          <a href="mailto:canopysfi@gmail.com">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="2x"
              className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
            />
          </a>
        </div>
        <div className="flex flex-col text-center py-6 space-y-2">
          <Link
            to="/services"
            className="text-2xl font-light hover:text-primary-highlighted active:text-primary-darkened"
          >
            Our Services
          </Link>

          <Link
            to="/projects"
            className="text-2xl font-light hover:text-primary-highlighted active:text-primary-darkened"
          >
            Our Work
          </Link>

          <Link
            to="/sitemap/sitemap-index.xml"
            className="text-2xl font-light hover:text-primary-highlighted active:text-primary-darkened"
          >
            Sitemap
          </Link>
        </div>
        <div className="text-sm text-center">
          <a
            href="https://goo.gl/maps/fDb9GJ8QJ6t8tZg48"
            className="text-primary hover:text-primary-highlighted active:text-primary-darkened underline"
          >
            10811 Pennsylvania 66, Clarion, PA 16214
          </a>
        </div>
        <div className="text-sm text-center py-4">
          <span>© {new Date().getFullYear()} Canopy Insulation, Co.</span>
        </div>
        <div className="text-center">
          <a
            ref={ccIconRef}
            href="https://carboncreek.agency"
            target="_blank"
            rel="noreferrer"
          >
            <CarbonCreekIcon className="h-5 w-5 mx-auto fill-secondary hover:fill-primary-highlighted hover:stroke-primary-highlighted" />
          </a>
          <Tooltips placement="top" ref={ccIconRef}>
            <TooltipsContent>
              Made with &hearts; by Carbon Creek
            </TooltipsContent>
          </Tooltips>
        </div>
      </footer>
    </>
  )
}

export default Footer
