import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Hamburger from "hamburger-react"
import * as React from "react"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config"
import Button from "./button"

const twConfig = resolveConfig(tailwindConfig)

const Header = ({ isNavOpen, setNavOpen, className }) => {
  return (
    <header className="sticky flex flex-row bg-white px-4 lg:px-12 py-1 shadow-md top-0 w-full z-50">
      <div className="hidden lg:block basis-2/5 self-center justify-self-start">
        <Button
          href="tel:814-229-0180"
          icon={faPhoneAlt}
          alt="Call Canopy Insulation at (814)-229-0180"
          className="align-middle hover:bg-primary-highlighted active:bg-primary-darkened active:shadow-none text-lg"
        >
          814.229.0180
        </Button>

        <a href="https://www.facebook.com/canopysfi" className="pl-8 pr-2">
          <FontAwesomeIcon
            icon={faFacebookF}
            alt="Facebook icon"
            size="2x"
            className="!align-middle hover:text-primary-highlighted hover:scale-125 duration-200"
          />
        </a>

        <a href="https://www.instagram.com/canopysfi/" className="px-2">
          <FontAwesomeIcon
            icon={faInstagram}
            alt="Instagram icon"
            size="2x"
            className="!align-middle hover:text-primary-highlighted hover:scale-125 duration-200"
          />
        </a>

        <a href="mailto:canopysfi@gmail.com" className="px-2">
          <FontAwesomeIcon
            icon={faEnvelope}
            alt="Email icon"
            size="2x"
            className="!align-middle hover:text-primary-highlighted hover:scale-125 duration-200"
          />
        </a>
      </div>

      <div className="lg:basis-1/5 text-center hover:scale-110 duration-200">
        <Link to="/">
          <StaticImage
            src="../images/canopy-logo.png"
            alt="Canopy Insulation, Co Logo"
            className="w-24 lg:w-32"
            placeholder="none"
          />
        </Link>
      </div>

      <nav className="hidden lg:flex flex-row basis-2/5 text-center place-self-stretch self-center">
        <span className="grow">
          <Link
            to="/about"
            activeClassName="text-primary"
            className="font-bold hover:text-primary-highlighted hover:text-base duration-200"
          >
            About
          </Link>
        </span>

        <span className="grow">
          <Link
            to="/services"
            activeClassName="text-primary"
            className="font-bold hover:text-primary-highlighted hover:text-base duration-200"
          >
            Services
          </Link>
        </span>

        <span className="grow">
          <Link
            to="/projects"
            activeClassName="text-primary"
            className="font-bold hover:text-primary-highlighted hover:text-base duration-200"
          >
            Projects
          </Link>
        </span>

        <span className="grow">
          <Link
            to="/articles"
            activeClassName="text-primary"
            className="font-bold hover:text-primary-highlighted hover:text-base duration-200"
          >
            Articles
          </Link>
        </span>

        <span className="grow">
          <Link
            to="/contact"
            activeClassName="text-primary"
            className="font-bold hover:text-primary-highlighted hover:text-base duration-200"
          >
            Contact
          </Link>
        </span>
      </nav>
      <div className="lg:hidden grow flex justify-end items-center">
        <Hamburger
          toggled={isNavOpen}
          toggle={setNavOpen}
          color={twConfig.theme.colors["tertiary"]}
          rounded="true"
        />
      </div>
    </header>
  )
}

export default Header
